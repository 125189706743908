import { Box, Card, Grid, Typography } from "@mui/material"
import SplineBlob from "./SplineBlob"
import { TypeAnimation } from "react-type-animation"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

const Home = () => {
    const [seed, setSeed] = useState(1);
    const selectedLanguage = useSelector((state: RootState) => state.language.key)
  
    useEffect(() => {
      setSeed(Math.random())
    }, [selectedLanguage])

    return (
        <Grid container spacing={2} mt={10}>
            <Grid item sm={6} display={'flex'} alignItems={'center'}>
                <Card variant="outlined" sx={{ width: '100%', margin: 2, borderRadius: 8, boxShadow: 3 }}>
                <Typography sx={{ margin: 2 }} textAlign={'center'} fontWeight="bold" variant="h3">
                        {t('WelcomeMessage')}
                    </Typography>
                    <Box component="div" sx={{ margin: 2 }} textAlign={'center'}>
                        <TypeAnimation
                            key={seed}
                            sequence={[t('MyName'), 1500, '', 500]}
                            speed={{type: 'keyStrokeDelayInMs', value: 120}}
                            style={{ fontSize: '2em', fontWeight: 'bold', color: "#008800" }}
                            repeat={Infinity}
                        />
                    </Box>
                </Card>
            </Grid>
            <Grid item sm={6}>
                <SplineBlob />
            </Grid>
        </Grid>
    )
}

export default Home
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { setLanguage } from './languageSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from "react-flags-select";
import { translateLanguageKeyToLabel, translateLabelToLanguageKey } from './languageKeyFormatter';
import './LanguageSelect.css'

const LanguageSelect = () => {
  const darkModeisActive = useSelector((state: RootState) => state.theme.darkModeIsActive)
  const selectedLanguage = useSelector((state: RootState) => state.language.key)
  const [actualSelection, setActualSelection] = useState<string>('')
  const dispatch = useDispatch()
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage])

  useEffect(() => {
    let newActualSelection = translateLanguageKeyToLabel(selectedLanguage)
    setActualSelection(newActualSelection)
  }, [selectedLanguage])

  const handleChange = (selectedValue: string) => {
    let newLanguageKey = translateLabelToLanguageKey(selectedValue);
    dispatch(setLanguage(newLanguageKey))
    i18n.changeLanguage(newLanguageKey)
  };

  return (
    darkModeisActive ?
      <ReactFlagsSelect
        id='language-select-dark'
        selected={actualSelection}
        onSelect={handleChange}
        countries={["DE", "US"]}
        customLabels={{ DE: "DE", US: "EN" }}
        searchable={false}
        showSelectedLabel={false}
        selectedSize={18}
      />
      :
      <ReactFlagsSelect
        id='language-select-light'
        selected={actualSelection}
        onSelect={handleChange}
        countries={["DE", "US"]}
        customLabels={{ DE: "DE", US: "EN" }}
        searchable={false}
        showSelectedLabel={false}
        selectedSize={18}
      />
  );
}

export default LanguageSelect;

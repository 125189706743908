import { Grid, Typography } from "@mui/material"
import { t } from "i18next"

const Content = () => {

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <Typography sx={{ marginTop: 10 }} fontWeight="bold" fontSize={24}>
                {t('ContentMessage')}
            </Typography>
        </Grid>
    )
}

export default Content
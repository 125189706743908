import { configureStore } from '@reduxjs/toolkit'
import themeSlice from '../theme/themeSlice'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import languageSlice from '../language/languageSlice';

const persistConfig = {
  key: 'global_redux_store',
  storage
}

const reducers = combineReducers({ 
  theme: themeSlice, 
  language: languageSlice
});

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
          serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
      }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import { Grid, Typography } from "@mui/material"
import { t } from "i18next"

const PageNotFound = () => {

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <Typography sx={{ marginTop: 10, textAlign: 'center' }} fontWeight="bold" fontSize={24}>
                {t('PageNotFound')}
            </Typography>
        </Grid>
    )
}

export default PageNotFound
import { createSlice } from "@reduxjs/toolkit";

export interface ThemeState {
    darkModeIsActive: boolean,
    mode: string
}

const initialState: ThemeState = {
    darkModeIsActive: true,
    mode: "dark",
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setToDarkmode: (state) => {
            state.darkModeIsActive = true;
            state.mode = "dark";
        },
        setToLightMode: (state) => {
            state.darkModeIsActive = false;
            state.mode = "light";
        },
        toggleTheme: (state) => {
            state.darkModeIsActive = !state.darkModeIsActive;
            state.mode = state.darkModeIsActive ? "dark" : "light";
        },
    },
})

export const { setToDarkmode, setToLightMode, toggleTheme } = themeSlice.actions

export default themeSlice.reducer
import { PaletteMode } from "@mui/material";

export const lightModePalette = {
    primary: {
        main: '#AEC09A'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
        default: "#DEDEDE",
        paper: "#CDCDCD",
    },
    action: {
        active: '#0000008a',
        hover: '#0000000a',
        selected: '#00000014',
        disabled: '#00000042',
        disabledBackground: '#0000001f',
    },
    text: {
        primary: '#000000de',
        secondary: '#00000099',
        disabled: '#00000061'
    },
};

export const darkModePalette = {
    divider: '#ffffff1f',
    background: {
        default: "#121212",
        paper: "#232323",
    },
    action: {
        active: '#fff',
        hover: '#ffffff14',
        selected: '#ffffff29',
        disabled: '#ffffff4d',
        disabledBackground: '#ffffff1f',
    },
    text: {
        primary: '#fff',
        secondary: '#ffffffb3',
        disabled: '#ffffff80'
    },
};

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light' ? lightModePalette : darkModePalette),
    },
});

export default getDesignTokens;

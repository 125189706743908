import { Box, Divider, List, ListItem, ListItemButton, ListItemText, CssBaseline, AppBar, Toolbar, IconButton, Button, Drawer } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { MyLogo } from "../../resources/MyLogo";
import { toggleTheme } from "../../theme/themeSlice";
import LanguageSelect from "../../language/LanguageSelect";
import { DarkLightModeToggleSwitch } from "../../resources/DarkLightThemeToggleSwitch";
import WebsiteRoutes from "../WebsiteRoutes";
import { navItems } from "../navItems";

const drawerWidth = 240;

const Navbar = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const darkModeisActive = useSelector((state: RootState) => state.theme.darkModeIsActive)
    const theme = useSelector((state: RootState) => state.theme.mode)
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleToggleThemeClick = () => {
        dispatch(toggleTheme())
    }

    const handleLogoClicked = () => {
        navigate("/");
        if (mobileOpen) {
            setMobileOpen(false);
        }
    }

    return (
        <Box component="div" sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Box component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        <MyLogo id="logo" fill={darkModeisActive ? "#fff" : "#000"} onClick={handleLogoClicked} />
                    </Box>
                    <Box component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item.text} sx={{ color: '#fff' }} onClick={() => navigate(item.to)}>
                                {item.text}
                            </Button>
                        ))}
                    </Box>
                    <Box component="div" sx={{ display: { xs: 'none', sm: 'block' }}}>
                        <Box component="div" display="flex" justifyContent="flex-end" alignItems="center">
                            <LanguageSelect />
                            <DarkLightModeToggleSwitch theme={theme} checked={darkModeisActive} onClick={handleToggleThemeClick} />
                        </Box>
                    </Box>
                    <Box component="div" sx={{ display: { xs: 'block', sm: 'none' }, flexGrow: 1, textAlign: 'center'}}>
                        <Box component="div" display="flex" justifyContent="flex-end" alignItems="center">
                            <LanguageSelect />
                            <DarkLightModeToggleSwitch theme={theme} checked={darkModeisActive} onClick={handleToggleThemeClick} />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* MOBILE */}
            <Box component="nav">
                <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }} sx={{opacity: "98%", display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }} >
                    <Box component="div" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                        <Box component="div" sx={{ my: 2 }}>
                            <MyLogo id="logo" fill={darkModeisActive ? "#fff" : "#000"} onClick={handleLogoClicked} />
                        </Box>
                        <Divider />
                        <List>
                            {navItems.map((item) => (
                                <ListItem key={item.text} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(item.to)}>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
            </Box>

            <WebsiteRoutes />
        </Box >
    );
}

export default Navbar
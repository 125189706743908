export const translateLabelToLanguageKey = (label : string) => {
    if (label === "DE") return "de";
    if (label === "US") return "en";
    return label;
}

export const translateLanguageKeyToLabel = (languageKey : string) => {
    if (languageKey === "de") return "DE";
    if (languageKey === "en") return "US";
    return languageKey;
}
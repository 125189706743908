import Spline from '@splinetool/react-spline';

export default function SplineBlob() {

  return (
    <Spline
      scene="https://prod.spline.design/nNWFTXUEXvCYtBRJ/scene.splinecode"
    />
  );
}

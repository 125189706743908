import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Content from "../pages/Content";
import PageNotFound from "../pages/PageNotFound";
import Home from "../pages/Home/Home";

const WebsiteRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/content" element={<Content />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default WebsiteRoutes
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { store } from './redux/store';

import en from "./language/en.json"
import de from "./language/de.json"

const selectedLanguage = store.getState().language.key || "en"; 

i18n
  .use(initReactI18next)
  .init({
    resources:{
      en: { translation: en},
      de: { translation: de},
    },
    lng: selectedLanguage
  });

export default i18n;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LanguageState {
    key: string
}

const initialState: LanguageState = {
    key: 'en',
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.key = action.payload;
        },
    },
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer
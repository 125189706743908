import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import Navbar from './components/Navbar/Navbar';
import { withTranslation } from 'react-i18next';
import React from 'react';
import getDesignTokens from './theme/themePalette';

const App = () => {
  const theme = useSelector((state: RootState) => state.theme.mode)
  const muiTheme = React.useMemo(() => createTheme(getDesignTokens(theme as PaletteMode)), [theme]);

  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={muiTheme}>
        <div className={`App ${theme} fullscreen`}>
          <Navbar/>
        </div>
      </ThemeProvider>
    </Suspense>
  );
}

export default  withTranslation() (App);